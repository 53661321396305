@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.show-actions-on-hover {
  .action {
    visibility: hidden;
  }

  &:hover .action {
    visibility: visible !important;
  }
}

/** Top Panel Styling Starts **/
.navigation-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navigation-icon {
  font-size: calc(1.275rem + 0.3vw);
  display: flex;
  flex: 1 0 auto;
  padding: 0.375rem 0.75rem;
  transition: all 0.2s ease-in-out;
  width: 3rem;
}

.forget-password {
  font-size: 0.875rem;
  padding: 0 5px 0 5px;
  position: relative;
  display: flex;
  height: 2rem;
  width: fit-content;
  align-items: center;
  justify-content: end;
  color: #808191;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.forget-password:hover {
  background-color: #f0effb;
  color: #AD181D;
}

.navigation-link {
  font-size: 0.875rem;
  padding: 0.75rem 0.75rem;
  position: relative;
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
  color: #808191;
  font-weight: 600;
  outline: none;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.navigation-link-info {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.navigation-link:hover {
  background-color: #f0effb;
  color: #AD181D;
}

.text-success {
  color: #28a745 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-danger {
  color: red !important;
}

.toast {
  border: 1px solid #cacaca !important;
  max-width: 32rem !important;
  min-width: none !important;
  width: max-content !important;
  padding: 3px 5px !important;
  min-height: 0 !important;
  border-radius: 8px !important;
}

/** Top Panel Styling Ends **/

::-webkit-scrollbar {
  width: .25rem;
  height: .25rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.judgment-text {
  a {
    color: #AD181D;
    font-weight: 600;
    text-decoration: underline;
  }

  table,
  th,
  td {
    border: 1px solid #555;
  }
}

.watermark {
  content: "EastLaw";
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 9rem;
  letter-spacing: -0.2rem;
  color: rgba(0, 0, 0, 0.1);
  /* Adjust the opacity to control the watermark visibility */
  transform: translate(-50%, -50%) rotate(-45deg);
  pointer-events: none;
  /* Ensures the watermark doesn't interfere with other elements */
}

.ck-editor__editable_inline {
  padding: 0 1.5rem !important;
  min-height: 30rem;
}

.ck-content .table {
  float: left;
}

.no-inherit {
  all: revert !important;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.text-tiny {
  font-size: .7em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.text-small {
  font-size: .85em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.text-big {
  font-size: 1.4em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.text-huge {
  font-size: 1.8em;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
img.image_resized {
  height: auto;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.image.image_resized img {
  width: 100%;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.image.image_resized>figcaption {
  display: block;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-left,
.image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-align-left,
.image-style-align-right {
  clear: none;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
p+.image-style-align-left,
p+.image-style-align-right,
p+.image-style-side {
  margin-top: 0;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-left,
.image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.image-inline {
  /*
   * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
   * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
   * This strange behavior does not happen with inline-flex.
   */
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.image-inline picture {
  display: flex;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.image-inline picture,
.image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}

.data-table-container {
  border-top-left-radius: 8px;
}

.PhoneInputInput {
  outline: 0 !important;
  height: 1.4375em !important;
}

.PhoneInput:focus-within {
  border: 1.5px gray solid;
}

@layer components {
  .rounded-container {
    @apply bg-white p-4 rounded-md border border-gray-300 shadow-sm;
  }

  .text-huge {
    @apply text-6xl font-bold
  }

  .badge-email-verified-no {
    @apply bg-red-100 text-red-800 border border-red-600 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md
  }

  .badge-email-verified-yes {
    @apply bg-green-100 text-green-800 border border-green-400 text-xs font-medium me-2 px-2.5 py-0.5 rounded-md
  }

  .small-badge {
    @apply bg-primary text-white rounded-full px-[5px] py-[1px] text-xs font-semibold shadow-xl capitalize
  }

  .gradient-text-red {
    @apply inline-block bg-gradient-to-r from-orange-900 to-primary bg-clip-text text-7xl text-transparent
  }

  /* Custom styles for the carousel */
  .carousel-container {
    @apply relative overflow-hidden w-full;
  }

  .carousel-wrapper {
    @apply flex transition-transform ease-in-out duration-500;
  }

  .carousel-slide {
    @apply w-full flex-none;
  }

  .arrow {
    @apply absolute top-1/2 transform -translate-y-1/2 bg-gray-100 shadow-md border border-slate-200 rounded-full w-10 h-10 flex items-center justify-center cursor-pointer z-10;
  }

  .arrow:hover {
    @apply bg-gray-200;
  }

  .arrow.prev {
    @apply left-1;
  }

  .arrow.next {
    @apply right-1;
  }

  .indicator-container {
    @apply absolute bottom-1 w-full flex justify-center gap-2;
  }

  .indicator {
    @apply bg-gray-100 border border-slate-200 shadow w-3 h-3 rounded-full cursor-pointer;
  }

  .indicator.selected {
    @apply bg-primary border-2 border-primary;
  }

  .nav-item {
    @apply font-medium;
  }

  .nav-item::after {
    content: "";
    display: block;
    width: 0;
    border-bottom: 3px solid #AD181D;
    transition: width 0.5s;
  }
  
  .nav-item:hover::after {
    width: 100%;
    background: var(--purple);
  }
}